html.uppercase{
	font-size: 20px;
}

.contrast{
	--bgBody: #282C2F;
	background: var(--bgBody);
	color: #fff;

	--bgFaixaTopo: #{map-get($cores, 'cinza-darken-1')};
	--bgMobileControls: #{map-get($cores, 'cinza-lighten-2')};
	--colorMobileControls: #FFF;

	--shadowTopo: rgba(255,255,255,.4);
	--colorContatoTopoMainTopo: #FFF;
	--colorHorarioTopo: #{lighten($cinza, 15%)};

	--bgRodape: var(--bgBody);
	--bgRodapeWhite: #282C2F;
	--colorRodape: #FFF;
}
