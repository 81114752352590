@import "_topo__faixa.scss";
@import "_mobile__controls.scss";
@import "_topo__main.scss";
@import "_main__menu.scss";
@import "_search.scss";

.topo{
	box-shadow: 0 0 8px var(--shadowTopo, #{rgba(#000,.4)});
	position: relative;
	z-index: 999;
	background: var(--bgBody, #FFF);

	&.fx{
		position: sticky;
		top: var(--offset);
		z-index: 999;
	}
}

body:not(.contrast) {
	.brand {
		.logo-contrast{
			display: none;
		}
	}
}

.contrast {
	.brand {
		img:not(.logo-contrast){
			display: none;
		}
	}
}


.backdrop{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000,.7);
	animation: fadeIn 0.6s linear;
	z-index: 999;

	&.hide{
		animation: fadeOut 0.6s linear;
	}
}
