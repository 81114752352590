@import "_fontfaces";

body{
	font-family: 'nunitoregular', sans-serif;
	color: #59646A;
	font-size: toRem(15);
}

a{
	color: inherit;
}
