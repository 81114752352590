.search{
	position: fixed;
	left: 50%;
	top: var(--t, 0px);
	z-index: 1000;
	transform: translate(-50%, var(--y, -150%));
	width: calc(100% - 30px);
	max-width: 600px;
	transition: all 0.6s linear;
	text-align: center;
	color: $laranja;
	text-shadow: 0 0 3px rgba(#000,.8);
	font-family: 'nunitobold';
	font-size: 20px;

	&.search--shown{
		--t:80px;
		--y: 0px;
	}

	.input__search{
		position: relative;
	}

	.form-control{
		border-radius: 25px;
		height: 50px;
		padding-left: 25px;
		padding-right: 50px;
		font-size: 20px;
	}

	.btn{
		background: $laranja;
		color: #FFF;
		padding: 0 0;
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		border:2px solid #FFF;
		position: absolute;
		right: 1px;
		top: 1px;
		border-radius: 50%;
		font-size: 20px;
		text-shadow: -1px 1px rgba(#000,.8);

		&:hover{
			background: darken($laranja, 10%);
		}
	}
}

body.search--shown{
	.topo__faixa,
	.topo__main,
	.mobile__controls,
	.wrapper,
	.rodape{
		filter: blur(4px) saturate(180%);
		transition: filter 0.4s linear;
	}
}
