.modal-cookies{
	.modal-content{
		background: $laranja;
		color: #FFF;
		text-align: center;
		font-size: 20px;
	}

	a{
		color: inherit
	}

	.btn{
		color: $laranja;
		padding: 0.5rem 2rem;

		&:hover{
			box-shadow: 0 0 8px rgba(#000,.8);
		}
	}

	.modal-body{
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
