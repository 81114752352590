.internas{
	padding-top: 80px;
	overflow: hidden;

	.page-header{
		text-align: center;
		margin-bottom: 40px;
	}
}

.content__editable{
	@include clearfix;
	margin-bottom: 40px;

	img{
		max-width: 100%;
		height: auto !important;
	}
}

.rowl__clientes{
	text-align: center;
	align-items: center;
	margin-bottom: 40px;
}


.form-contato{
	.form-control{
		border-radius: 0;
		background: #EEEEEE;
		min-height: 42px;
		border-color: #EEE;

		&::placeholder{
			color: #333333;
		}
	}

	textarea.form-control{
		min-height: 168px;
	}
}

.nav__faq{
	list-style: none;
	padding-left: 0;

	li{
		border-bottom: 1px solid #CCC;
	}

	a{
		display: block;
		padding: 10px 0.75rem;

		&:before{
			content: fa-content($fa-var-chevron-down);
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			display: inline-block;
			float: right;
			margin-left: 10px;
		}

		&:hover{
			text-decoration: none;
			background: #EEE;
			color: #000;
		}
	}

	.active a:before{
		transform: rotate(-90deg);
	}
}

.faq__item{
	background: #DDDDDD;
	border-radius: 6px;
	margin-bottom: 1rem;

	.btn{
		width: 100%;
		text-align: left;
		border-radius: 6px;
		color: #59646A;
		box-shadow: none;

		&:before{
			content: fa-content($fa-var-chevron-down);
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			display: inline-block;
			float: right;
			margin-left: 10px;
			transition: all 0.3s linear;
		}

		&[aria-expanded="true"]:before{
			transform: rotate(-180deg);
		}
	}

	.faq__resposta {
		color: #59646A;
		padding:  15px 15px 15px;

		&:before{
			content:'';
			display: block;
			border-top: 4px solid currentcolor;
			margin-bottom: 15px;
			opacity: 0.5;
		}
	}
}
.certificacoes{
	.servico{
		margin-bottom: 30px;
		text-align: center;

		&:hover{
			.servico__title{
				color: $laranja;
			}
		}
	}

	.servico__foto{
		margin-bottom: 10px;

		lazy-image,
		img {
			border-radius: 20px;
		}
	}

	.servico__title {
		font-family: 'robotoblack', sans-serif;
		font-size: toRem(16);
		margin-bottom: 10px;
		@include line-clamp();
	}

	.servico__desc{
		font-size:  toRem(13);
		@include line-clamp();
	}


	.servico a:hover{
		text-decoration: none;
		color: #333333;

		.servico__title{
			color: $laranja;
		}
	}
}
