.main__menu{
	font-family: 'nunitobold', sans-serif;
	font-size: toRem(15);

	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	a:hover{
		text-decoration: none;
	}

	.dropdown-menu{
		border-radius: 0;
		border: none;
		background: $laranja;
		padding: 0 0;
		color: #FFF;

		a{
			display: block;
			padding: 10px 15px;
			color: #FFF;
		}

		li + li:before{
			content:'';
			display: block;
			border-top: 1px solid currentcolor;
			width: 100%;
			max-width: calc(100% - 30px);
			margin:0 auto;
			opacity: 0.4;
		}
	}
}

@include media-breakpoint-up(xl) {
	.main__menu{
		position: relative;
		perspective: 3px;
		transform: translateY(50%);
		padding: 0 10px;
		filter: drop-shadow(0 4px 4px rgba(#000,.4));

		&:before{
			content:'';
			width: 100%;
			height: 100%;
			position: absolute;
			background: var(--bgMainMenu, #FFF);
			left: 0;
			top: 0;
			z-index: -1;
			--fr: calc(100% - 10px);
			clip-path: polygon(0px 10px, 10px 0, var(--fr) 0, 100% 10px, 100% var(--fr), var(--fr) 100%, 10px 100%, 0 var(--fr))
		}

		& > ul{
			display: flex;
			justify-content: space-between;

			& > li{
				display: flex;
			}

			& > li > a{
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 20px 10px;
				color: var(--colorMenuLink, #59646A);
				text-align: center;

				&:hover{
					color: $laranja;
				}
			}

			& > li.active > a,
			& > li.show > a{
				color: $laranja;
			}
		}

		.dropdown-menu{
			text-align: center;
		}
	}

	.contrast{
		--bgMainMenu: var(--bgFaixaTopo);
		--colorMenuLink: #FFF;
	}
}


@include media-breakpoint-only(lg) {
	.main__menu{
		position: relative;
		perspective: 3px;
		transform: translateY(50%);
		padding: 0 5px;
		filter: drop-shadow(0 4px 4px rgba(#000,.4));

		&:before{
			content:'';
			width: 100%;
			height: 100%;
			position: absolute;
			background: var(--bgMainMenu, #FFF);
			left: 0;
			top: 0;
			z-index: -1;
			--fr: calc(100% - 10px);
			clip-path: polygon(0px 10px, 10px 0, var(--fr) 0, 100% 10px, 100% var(--fr), var(--fr) 100%, 10px 100%, 0 var(--fr))
		}

		& > ul{
			display: flex;
			justify-content: space-between;

			& > li{
				display: flex;
			}

			& > li > a{
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 15px 5px;
				color: var(--colorMenuLink, #59646A);
				text-align: center;

				&:hover{
					color: $laranja;
				}
			}

			& > li.active > a,
			& > li.show > a{
				color: $laranja;
			}
		}

		.dropdown-menu{
			text-align: center;
		}
	}

	.contrast{
		--bgMainMenu: var(--bgFaixaTopo);
		--colorMenuLink: #FFF;
	}
}


@include media-breakpoint-down(md) {
	.main__menu {
		a{
			padding: 10px 15px;
			display: block;
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;
		}

		.dropdown-menu{
			transform: none !important;
			padding: 0 !important;
			float: none;
			border-radius: 0;
			margin: 0 0 !important;
			position: relative !important;
			box-shadow: none;
			border: none;
		}

		& > ul {

			& > .active > a,
			& > .show > a{
				border-color: darken($laranja, 10%);
			}

		}
	}

	body:not(.contrast) {
		.main__menu{
			a{
				color: inherit;
			}

			& > ul {

				& > li:hover > a{
					background: #EEE;
				}

				& > .active > a,
				& > .show > a,
				& > .active:hover > a,
				& > .show:hover > a{
					background: $laranja;
					color: #FFF;
				}

			}
		}
	}

	.contrast{
		.main__menu{
			a{
				color: #FFF;
			}

			& > ul {

				& > li:hover > a{
					background: map-get($cores, 'cinza-darken-1');
				}

				& > .active > a,
				& > .show > a,
				& > .active:hover > a,
				& > .show:hover > a{
					background: $laranja;
					color: #FFF;
				}

			}
		}
	}
}
