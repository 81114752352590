.topo__faixa{
	padding: 10px 0;
	background: var(--bgFaixaTopo,map-get($cores, 'cinza'));
	color: #FFF;

	.md__switch .md__switch__label{
		background: #CCC;
	}

	a{
		color: inherit;

		&:hover{
			color: $laranja;
		}
	}
}

.acessibilidade,
.acessibilidade .content,
.acessibilidade__fonts,
.acessibilidade__contrast,
.topo__faixa .contato__topo{
	display: flex;
	align-items: center;

	.title{
		margin-right: 10px;
	}
}

.acessibilidade{
	.md__radio__icon + .md__radio__icon{
		margin-left: 10px;
	}
}

@include media-breakpoint-up(lg) {
	.topo__faixa{
		.container {
			display: flex;
			align-items: center;
		}
	}

	.boas__vindas{
		flex-grow: 1;
	}

	.topo__faixa .contato__topo{
		margin-right: 10px;
	}

	.acessibilidade{
		border-left:1px solid currentcolor;
		padding-left: 10px;
	}

	.acessibilidade__contrast{
		margin-left: 10px;
	}
}

@include media-breakpoint-only(lg) {
	.uppercase{

		.topo__faixa{
			white-space: nowrap;

			.contato__topo{
				display: none;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.topo__faixa{

		.contato__topo{
			display: none;
		}
	}
}

@include media-breakpoint-only(md) {
	.topo__faixa {
		white-space: nowrap;

		.container{
			display: flex;
			flex-wrap: wrap;
			align-item: center;
			max-width: 100%;
		}

		.acessibilidade{
			margin-left: auto;
		}
	}
}

@include media-breakpoint-down(sm) {
	.topo__faixa {
		text-align: center;

		.boas__vindas{
			margin-bottom: 10px;
		}

		.acessibilidade{
			justify-content: center;
			flex-wrap: wrap;
		}
	}
}

@include media-breakpoint-only(sm) {
	.acessibilidade {
		.acessibilidade__contrast{
			margin-left: 10px;
			padding-left: 10px;
			border-left: 1px solid currentcolor;
		}
	}
}
