@font-face {
    font-family: 'nunitobold';
    font-display: swap;
    src: url('#{$fonts}/Nunito-Bold/nunito-bold-webfont.eot');
    src: url('#{$fonts}/Nunito-Bold/nunito-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Nunito-Bold/nunito-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/Nunito-Bold/nunito-bold-webfont.woff') format('woff'),
         url('#{$fonts}/Nunito-Bold/nunito-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/Nunito-Bold/nunito-bold-webfont.svg#nunitobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitoregular';
    font-display: swap;
    src: url('#{$fonts}/nunito_regular/nunito-regular-webfont.eot');
    src: url('#{$fonts}/nunito_regular/nunito-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/nunito_regular/nunito-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/nunito_regular/nunito-regular-webfont.woff') format('woff'),
         url('#{$fonts}/nunito_regular/nunito-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/nunito_regular/nunito-regular-webfont.svg#nunitoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
