.md__switch{
	display: flex;
	width: 50px;
	height: 20px;
	align-items: center;
	position: relative;

	.md__switch__input{
		display: none;
	}

	.md__switch__label{
		width: calc(100% - 4px);
		height: 16px;
		border-radius: 16px;
		margin: auto;
		display: block;
		background: #777;
		transition: background 0.3s linear;
		transition-delay: 0.4s;
		cursor: pointer;
	}

	.md__switch__marker{
		width: 20px;
		height: 20px;
		position: absolute;
		left:0;
		top: 0;
		background: #FFF;
		border-radius: 100%;
		border: 1px solid rgba(#000,.1);
		box-shadow: 0 0 3px rgba(#000,.4);
		transition:all 0.4s linear;

		&:before{
			content:'';
			display: block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: rgba(#CCC, 0.4);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%) scale(var(--scale, 0));
			transition: transform 0.3s linear;
		}
	}

	.md__switch__input:checked {
		& ~ .md__switch__label {
			background: orange;

			.md__switch__marker{
				transform: translateX(30px);
			}
		}

	}

	.md__switch__input:focus {
		& ~ .md__switch__label {
			--scale: 1;
		}
	}
}
