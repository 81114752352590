#quem__somos{
	padding-top: 75px;
	padding-bottom: 75px;

	@include media-breakpoint-up(lg) {
		background-image: var(--lazy-bg, none);
		background-position: center top;
		background-repeat: no-repeat;
		min-height: 664px;
	}

	hgroup{
		width: 100%;
		max-width: 600px;
		margin-bottom: 30px;

		background: url(../images/bg-title-qm-somos.png) center right no-repeat;
		min-height: 141px;

		h1{
			font-size: toRem(13);
			color: $laranja;
		}

		h2{
			font-size: 40px;
		}
	}
}

#chamadas__index{
	background: $cinza;
	color: #FFF;

	.chamada{
		padding: 30px 0;
		font-size: toRem(13);
		display: flex;
	}

	.chamada__icon{
		min-width: 65px;
	}

	.chamada__content{
		flex-grow: 1;
		align-self: center;
	}

	.chamada__title{
		font-family: 'nunitobold', sans-serif;
	}

	@include media-breakpoint-up(lg) {
		.container{
			display: grid;
			grid-template-columns: repeat(4,1fr);
			grid-column-gap: 30px;
		}
	}

	@include media-breakpoint-between(sm, md) {
		.container{
			display: grid;
			grid-template-columns: repeat(2,1fr);
			grid-column-gap: 30px;
		}
	}
}

#servicos__produtos {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;

	h1{
		font-size: 40px;
	}

	.hgroup{
		margin-bottom: 15px;
	}

	.btn{
		color: inherit;
	}
}


.produto{
	position: relative;
	width: 100%;
	max-width: 348px;
	margin-left: auto;
	margin-right: auto;
	mask-image: url(../images/mask__produto.png);
	mask-position: center center;
	mask-size: 100% 100%;

	.produto__foto{
		filter: grayscale(100%);
		transition: all 0.3s linear;
	}

	&:hover{
		.produto__foto{
			filter: grayscale(0%);
		}

		.produto__description__block{
			opacity: 1;
		}
	}

	.produto__description__block{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba($cinza,.7);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px;
		text-align: center;
		color: #FFF;
		font-size: toRem(15);
		opacity: 0;
		transition: all 0.3s linear;
	}

	.produto__descrition{
		@include line-clamp(6);
	}

	.produto__nome{
		font-family: 'nunitobold';
		font-size: 20px;
	}

	.btn{
		color: #FFF;
		font-size: toRem(15);
	}
}

.select__owl__slider{
	list-style: none;
	margin-bottom: 40px;
	padding-left: 0;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	li + li:before{
		content: '|';
		display: inline-block;
		margin: 0 15px;
	}

	.active{
		color: $laranja;
	}
}

.owl__servicos{
	.owl-prev,
	.owl-next{
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: calc(50% - 25px);
		border-radius: 50%;
		outline: none !important;

		&.disabled{
			background: $cinza !important;
		}

		&:not(.disabled){
			background: $laranja !important;
		}
	}

	.owl-prev{
		left: -15px;
	}

	.owl-next{
		right: -15px;
	}
}

#depoimentos {
	text-align: center;
	padding-bottom: 50px;

	h1{
		font-size: 40px;
		margin-bottom: 30px;
		font-family: 'nunitobold';
	}

	.carousel{
		width: 100%;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		min-height: 198px;
		background: url(../images/bg-depoimentos.png) no-repeat;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.autor{
		font-family: 'nunitobold';
	}

	.carousel-indicators{
		position: relative;
		bottom: 0;
		margin-top: 30px;

		li{
			width: 1rem;
			height: 1rem;
			border: none;
			background: currentColor;
			opacity: 1;
			border:1px solid $cinza;
			border-radius: 50%;

			&.active{
				background: #FFF;
			}
		}
	}
}

.contrast {
	#depoimentos {
		.carousel-indicators{
			li{
				background: #FFF;
				border-color: #FFF;

				&.active{
					background: $cinza;
				}
			}
		}
	}
}
