.mobile__controls{
	grid-template-columns: 50px 1fr 50px;
	height: 75px;
	background: var(--bgMobileControls, #FFF);

	.brand{
		padding: 5px 10px;
		margin: 0 auto;
		width: 100%;
		max-width: 180px;
	}

	.btn__mobile{
		border-radius: 0;
		background: transparent;
		box-shadow: none !important;
	}

	@include media-breakpoint-down(md) {
		display: grid;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}
