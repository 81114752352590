.md__radio__icon{
	display: inline-flex;
	align-items: center;

	.md__radio__input{
		display: none;
	}

	.md__radio__label{
		margin: 0;
		width:2em;
		height:2em;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		background: #FFF;
		color: #000;
		cursor: pointer;
		position: relative;

		&:before{
			content:'';
			background: rgba(#ccc,.4);
			position: absolute;
			width: 200%;
			height: 200%;
			border-radius: 50%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scale(var(--scale, 0));
			transition: all 0.4s linear;
		}
	}

	.md__radio__input:checked ~ .md__radio__label{
		background: $laranja;
		color: color-contrast($laranja);
	}

	.md__radio__input:focus ~ .md__radio__label{
		--scale: 1;
	}
}
