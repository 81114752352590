.rodape{
	min-height: 200px;
	background: linear-gradient(to bottom, var(--bgRodapeWhite, #FFF) 0px, var(--bgRodape, #D0D3D5) 120px, var(--bgRodape, #D0D3D5) 100%);
	padding-top: 40px;
	font-size: toRem(14);
	color: var(--colorRodape, inherit);
}

.selo__segura{
	max-width: 100px;
	width: 100%;
	min-width: unset;
	margin-left: 0px;
	margin-right: auto;
	margin-top: 15px;
}
.selos__segura{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px;
	flex-wrap: wrap;

	.selo__segura{
		margin: 2px;
		max-width: 70px;
	}
}
.endereco{
	padding-top: 20px;
	padding-bottom: 30px;
}

.selo__custom{
	margin-bottom: 10px;
}
.segura__selos{
	display: flex;
	margin-bottom: 20px;

	.selo__custom{
		margin-right: 10px;
	}
}
.title__footer{
	font-family: 'nunitobold', sans-serif;
	font-size: toRem(15);
	color: $laranja;
	margin-bottom: 20px;
}

.footer__nav{
	ul{
		padding-left: 0;
		list-style: none;
	}

	a:hover,
	.active a{
		color: $laranja;
	}
}

.input__newsletter{
	position: relative;

	.btn{
		position: absolute;
		right: 3px;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 50%;
		height: 31px;
		width: 31px;
		@extend .btn-laranja, .btn-sm;
	}
}

.ultimos__posts{
	.post{
		display: flex;
		margin-bottom: 15px;

		& > a{
			display: flex;
			width: 100%;
		}
	}

	.post__foto{
		min-width: 55px;
		margin-right: 10px;

		img{
			border-radius: 7px;
		}
	}

	.post__content{
		flex-grow: 1;
	}

	.post__title{
		@include line-clamp();
	}

	.post__date{
		font-family: 'nunitobold', sans-serif;
	}
}

.rodape__wrapper{
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-columns-gap: 30px;
	}
}


.faixa__topo{
	background: #5F6A70;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #FFF;

	a:hover{
		color: #FFF;
	}
}

.contato__rodape{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	font-family: 'nunitobold', sans-serif;

	.icon{
		vertical-align: bottom;
	}

	@include media-breakpoint-up(xl) {
		white-space: nowrap;

		.title{
			margin-right: 10px;
		}

		.flor__online{
			margin-left: 10px;
			border-left:1px solid currentcolor;
			padding-left: 10px;
		}
	}
}

.creditos{
	font-size: toRem(12);
	text-align: center;


	svg{
		fill: currentColor;
		width: toEm(29,12);
		height: toEm(15, 12);
	}
}
