.topo__main{

	.contato__topo{
		color: var(--colorContatoTopoMainTopo, #59646A);
		display: flex;
		font-size: toRem(14);

		.icon__area{
			margin-right: 10px;
		}

		.icon__mask{
			background: currentColor;
		}

		.numero{
			line-height: 1;
			font-size: toRem(22);
		}

		.horario{
			color: var(--colorHorarioTopo, #000);
		}

		a{
			color: inherit;

			&:hover{
				color: $laranja;
			}
		}
	}
}

.topo__main--btns{
	.btn__search{
		width: 48px;
		height: 48px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		border-radius: 50%;
		font-size: 20px;

		background: var(--bgBtnMainTopo, $cinza);
		color: var(--colorBtnMainTopo, #FFF);
		margin-left: 10px;
		box-shadow: none;
		transition: background 0.3s linear;

		&:hover,
		&[aria-expanded="true"]{
			text-decoration: none !important;
			background: var(--bgBtnMainTopoHover, $laranja);
			color: var(--colorBtnMainTopoHover, #FFF);
		}
	}

	.redes__sociais{
		a{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			margin:0 10px;
			font-size: 20px;
			transition: background 0.3s linear;

			background: var(--bgBtnMainTopo, $cinza);
			color: var(--colorBtnMainTopo, #FFF);

			&:hover{
				text-decoration: none !important;
				background: var(--bgBtnMainTopoHover, $laranja);
				color: var(--colorBtnMainTopoHover, #FFF);
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.topo__main{
		& > .content{
			width: 100%;
			max-width: map-get($container-max-widths, xl);
			margin-left: auto;
			margin-right: auto;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			padding-top: 15px;
			padding-left: 15px;
			padding-right: 15px;
		}

		.contato__topo{
			grid-column: 1/2;
			grid-row: 1/2;
			align-self: center;
		}

		.brand{
			grid-column: 2/3;
			grid-row: 1/2;
			text-align: center;
		}

		.topo__main--btns{
			grid-column: 3/4;
			grid-row: 1/2;
			display: flex;
			margin-left: auto;
			align-self: center;
		}

		.main__menu{
			grid-column: span 3;
		}

	}
}


@include media-breakpoint-only(lg) {
	.topo__main{
		& > .content{
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			padding-top: 15px;
			padding-left: 15px;
			padding-right: 15px;
		}

		.contato__topo{
			grid-column: 1/2;
			grid-row: 1/2;
			align-self: center;
		}

		.brand{
			grid-column: 2/3;
			grid-row: 1/2;
			text-align: center;
		}

		.topo__main--btns{
			grid-column: 3/4;
			grid-row: 1/2;
			display: flex;
			margin-left: auto;
			align-self: center;
		}

		.main__menu{
			grid-column: span 3;
		}

	}
}

@include media-breakpoint-down(md) {
	.topo__main{
		width: 270px;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: var(--bgMainTopo, #FFF);
		overflow: auto;
		transform: translateX(var(--x, -270px));
		transition: all 0.6s linear;
		z-index: 1000;

		&.topo__main--shown {
			--x: 0px;
		}

		.brand{
			padding: 30px 15px;
			text-align: center;
		}

		.contato__topo{
			padding: 10px;
			border-top: 1px solid #CCC;
			margin-top: 15px;
		}

		.btn__search{
			display: none !important;
		}

		.redes__sociais{
			padding: 10px;
			text-align: center;
			border-top: 1px solid #CCC;
		}
	}

	body.topo__main--shown{
		.topo__faixa,
		.mobile__controls,
		.wrapper,
		.rodape{
			filter: blur(4px);
			transition: filter 0.4s linear;
		}
	}

	.contrast{
		--bgMainTopo: #{map-get($cores, 'cinza')};
	}
}
