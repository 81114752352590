.btn-pill{
	border-radius: 2rem;
}

.btn-ripple{
	overflow: hidden;
	position: relative;

	& * {
		pointer-events: none;
	}

	.ripple{
		position: absolute;
		width:100%;
		height: 100%;
		background: currentColor;
		opacity: 0.6;
		clip-path: circle(15px at var(--x, 50%) var(--y, 50%));
		left: 0;
		top: 0;
		animation: ripple 0.6s linear;
	}
}

@keyframes ripple {
	0% {
		clip-path: circle(15px at var(--x, 50%) var(--y, 50%));
		opacity: 0.6;
	}

	100% {
		clip-path: circle(150% at var(--x, 50%) var(--y, 50%));
		opacity: 0;
	}
}


.btn-laranja{
	@include button-variant($laranja, $laranja);
}


.btn-voltar,
.btn-enviar{
	width: 100%;
	max-width: 214px;
}
